import revive_payload_client_GwkxC0tCUR from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_sass@1.77.8_typescript@5.6.3_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_sreqMyWg1S from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_sass@1.77.8_typescript@5.6.3_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_bdmGbkK2YE from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_sass@1.77.8_typescript@5.6.3_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_qy2uosmr1P from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_sass@1.77.8_typescript@5.6.3_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_4NRXaJgclP from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_sass@1.77.8_typescript@5.6.3_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_AzcqnOGX2y from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_sass@1.77.8_typescript@5.6.3_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Lw49zrV3Gt from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_sass@1.77.8_typescript@5.6.3_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CnUVl1sblT from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.4_typescript@5.6.3_vue@3.5.13/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/main/.nuxt/components.plugin.mjs";
import prefetch_client_GrLnKoD8JX from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_sass@1.77.8_typescript@5.6.3_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_nYC2dJgjwh from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.21.3_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import unocss_MzCDxu9LMj from "/vercel/path0/apps/main/.nuxt/unocss.mjs";
import _05_sentry_j5CkoUzNHJ from "/vercel/path0/apps/main/plugins/05.sentry.ts";
import markdowneditor_FagzXM5Mcq from "/vercel/path0/apps/main/plugins/markdowneditor.ts";
import trpc_0KeCZOBKLF from "/vercel/path0/apps/main/plugins/trpc.ts";
import vue_query_wrmMkNpEpe from "/vercel/path0/apps/main/plugins/vue-query.ts";
import vuetify_7h9QAQEssH from "/vercel/path0/apps/main/plugins/vuetify.ts";
export default [
  revive_payload_client_GwkxC0tCUR,
  unhead_sreqMyWg1S,
  router_bdmGbkK2YE,
  payload_client_qy2uosmr1P,
  navigation_repaint_client_4NRXaJgclP,
  check_outdated_build_client_AzcqnOGX2y,
  chunk_reload_client_Lw49zrV3Gt,
  plugin_vue3_CnUVl1sblT,
  components_plugin_KR1HBZs4kY,
  prefetch_client_GrLnKoD8JX,
  i18n_nYC2dJgjwh,
  unocss_MzCDxu9LMj,
  _05_sentry_j5CkoUzNHJ,
  markdowneditor_FagzXM5Mcq,
  trpc_0KeCZOBKLF,
  vue_query_wrmMkNpEpe,
  vuetify_7h9QAQEssH
]