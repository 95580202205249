import { ref, computed } from "vue";
import {
  CURRENCIES,
  type CurrencyCode,
  formatCurrency as formatCurrencyUtil,
  parseCurrency as parseCurrencyUtil,
} from "@mono/constants/lib/currency";
import Decimal from "decimal.js";

// state outside the composable function represent shared global app state
const currencyCode = ref<CurrencyCode>("sar");
const currentCurrency = computed(() => {
  return CURRENCIES.find((c) => c.code === currencyCode.value)!;
});

// methods
const setCurrency = (code: CurrencyCode) => {
  currencyCode.value = code;
};
const clearCurrency = () => {
  currencyCode.value = "sar";
};

export const useCurrency = ({
  extraPrecision = false,
  showZero,
}: { extraPrecision?: boolean; showZero?: boolean } = {}) => {
  const precision =
    currentCurrency.value.precision +
    (extraPrecision ? currentCurrency.value.extraPrecision : 0);

  const currencyPlaceholder = computed<string>(() => {
    return `0.${"0".repeat(precision)}`;
  });

  const currencyRegex = computed(() => {
    const zeroLeadingRegex = `0(\\.[0-9]{0,${precision}})?`;
    const nonZeroLeadingRegex = `[1-9][0-9]*(\\.[0-9]{0,${precision}})?`;
    return new RegExp(`^(${zeroLeadingRegex}|${nonZeroLeadingRegex})$`);
  });

  const formatCurrency = (value: number) => {
    if (value === 0 && !showZero) {
      // if value is 0 and showZero is false, return empty string
      // to prevent displaying zero's in the UI
      return "";
    }

    if (value === 0 && showZero) {
      // if value is 0 and showZero is true, return the currency placeholder
      // to display zero's in the UI as placeholders
      return new Decimal(0).toFixed(precision);
    }

    // remove trailing zeros
    return formatCurrencyUtil({
      value,
      currencyCode: currencyCode.value,
      extraPrecision,
    });
  };

  const parseCurrency = (value: string | number) => {
    if (isNaN(parseFloat(value.toString())) === true) {
      return 0;
    }

    return parseCurrencyUtil({
      value: new Decimal(value).toNumber(),
      currencyCode: currencyCode.value,
      extraPrecision,
    }).toNumber();
  };

  return {
    Decimal,
    currentCurrency,
    currencyPlaceholder,
    setCurrency,
    clearCurrency,
    currencyRegex,
    formatCurrency,
    parseCurrency,
  };
};
